!(function(e) {
    const o = (e.cs = e.cs || {});
    (o.dictionary = Object.assign(o.dictionary || {}, {
        "%0 of %1": "%0 z %1",
        Aquamarine: "Akvamarínová",
        Black: "Černá",
        "Block quote": "Citace",
        Blue: "Modrá",
        Bold: "Tučné",
        "Bulleted List": "Odrážky",
        Cancel: "Zrušit",
        "Cannot upload file:": "Soubor nelze nahrát:",
        "Centered image": "Obrázek zarovnaný na střed",
        "Change image text alternative": "Změnit alternativní text obrázku",
        "Choose heading": "Zvolte nadpis",
        Column: "Sloupec",
        "Could not insert image at the current position.":
            "Na současnou pozici nelze vložit obrázek.",
        "Could not obtain resized image URL.":
            "Nelze získat URL obrázku se změněnou velikostí.",
        "Decrease indent": "Zmenšit odsazení",
        "Delete column": "Smazat sloupec",
        "Delete row": "Smazat řádek",
        "Dim grey": "Tmavě šedá",
        Downloadable: "Ke stažení",
        "Dropdown toolbar": "Rozbalovací panel nástrojů",
        "Edit block": "Upravit blok",
        "Edit link": "Upravit odkaz",
        "Editor toolbar": "Panel nástrojů editoru",
        "Enter image caption": "Zadejte popis obrázku",
        "Full size image": "Obrázek v plné velikosti",
        Green: "Zelená",
        Grey: "Šedá",
        "Header column": "Sloupec záhlaví",
        "Header row": "Řádek záhlaví",
        Heading: "Nadpis",
        "Heading 1": "Nadpis 1",
        "Heading 2": "Nadpis 2",
        "Heading 3": "Nadpis 3",
        "Heading 4": "Nadpis 4",
        "Heading 5": "Nadpis 5",
        "Heading 6": "Nadpis 6",
        "Image toolbar": "Panel nástrojů obrázku",
        "image widget": "ovládací prvek obrázku",
        "Increase indent": "Zvětšit odsazení",
        "Insert column left": "Vložit sloupec vlevo",
        "Insert column right": "Vložit sloupec vpravo",
        "Insert image": "Vložit obrázek",
        "Insert image or file": "Vložit obrázek nebo soubor",
        "Insert media": "Vložit média",
        "Insert paragraph after block": "Vložte odstavec za blok",
        "Insert paragraph before block": "Vložte odstavec před blok",
        "Insert row above": "Vložit řádek před",
        "Insert row below": "Vložit řádek pod",
        "Insert table": "Vložit tabulku",
        "Inserting image failed": "Vložení obrázku selhalo",
        Italic: "Kurzíva",
        "Left aligned image": "Obrázek zarovnaný vlevo",
        "Light blue": "Světle modrá",
        "Light green": "Světle zelená",
        "Light grey": "Světle šedá",
        Link: "Odkaz",
        "Link URL": "URL odkazu",
        "Media URL": "URL adresa",
        "media widget": "ovládací prvek médií",
        "Merge cell down": "Sloučit s buňkou pod",
        "Merge cell left": "Sloučit s buňkou vlevo",
        "Merge cell right": "Sloučit s buňkou vpravo",
        "Merge cell up": "Sloučit s buňkou nad",
        "Merge cells": "Sloučit buňky",
        Next: "Další",
        "Numbered List": "Číslování",
        "Open in a new tab": "Otevřít v nové kartě",
        "Open link in new tab": "Otevřít odkaz v nové kartě",
        Orange: "Oranžová",
        Paragraph: "Odstavec",
        "Paste the media URL in the input.":
            "Vložte URL média do vstupního pole.",
        Previous: "Předchozí",
        Purple: "Fialová",
        Red: "Červená",
        Redo: "Znovu",
        "Rich Text Editor": "Textový editor",
        "Rich Text Editor, %0": "Textový editor, %0",
        "Right aligned image": "Obrázek zarovnaný vpravo",
        Row: "Řádek",
        Save: "Uložit",
        "Select all": "Vybrat vše",
        "Select column": "Vybrat sloupec",
        "Select row": "Vybrat řádek",
        "Selecting resized image failed":
            "Výběr obrázku se změněnou velikostí selhal",
        "Show more items": "Zobrazit další položky",
        "Side image": "Postranní obrázek",
        "Split cell horizontally": "Rozdělit buňky horizontálně",
        "Split cell vertically": "Rozdělit buňky vertikálně",
        "Table toolbar": "Panel nástrojů tabulky",
        "Text alternative": "Alternativní text",
        "The URL must not be empty.": "URL adresa musí být vyplněna.",
        "This link has no URL": "Tento odkaz nemá žádnou URL",
        "This media URL is not supported.":
            "Tato adresa bohužel není podporována.",
        "Tip: Paste the URL into the content to embed faster.":
            "Rada: Vložte URL přímo do editoru pro rychlejší vnoření.",
        Turquoise: "Tyrkysová",
        Undo: "Zpět",
        Unlink: "Odstranit odkaz",
        "Upload failed": "Nahrání selhalo",
        "Upload in progress": "Probíhá nahrávání",
        White: "Bílá",
        "Widget toolbar": "Panel nástrojů ovládacího prvku",
        Yellow: "Žlutá",
        Underline: "Podtržené",
        Strikethrough: "Přeškrtnuté",
        "Font Size": "Velikost písma",
        "Font Family": "Písmo",
        "Font Color": "Barva textu",
        "Font Background Color": "Barva zvýraznění",
        "Text alignment": "Zarovnání textu",
        "Remove Format": "Odebrat formát",
        "To-do List": "Seznam úkolů",
        Superscript: "Horní index",
        Subscript: "Dolní index",
        "Insert HTML": "Vložit HTML",
        "Remove color": "Odstranit barvu",
        "Align left": "Zarovnat doleva",
        "Align right": "Zarovnat vpravo",
        "Align center": "Zarovnat střed",
        Justify: "Do bloku",
        Disc: "Disk",
        Circle: "Kruh",
        Square: "Čtverec",
        Decimal: "Celá čísla",
        "Decimal with leading zero": "Celá čísla začínající nulou",
        "Lower–roman": "Římská malá",
        "Upper-roman": "Římská velká",
        "Lower-latin": "Malá písmena",
        "Upper-latin": "Velká písmena"
    })),
        (o.getPluralForm = function(e) {
            return 1 == e && e % 1 == 0
                ? 0
                : e >= 2 && e <= 4 && e % 1 == 0
                ? 1
                : e % 1 != 0
                ? 2
                : 3;
        });
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}));
